exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-this-site-tsx": () => import("./../../../src/pages/about-this-site.tsx" /* webpackChunkName: "component---src-pages-about-this-site-tsx" */),
  "component---src-pages-donate-to-ukraine-foundations-tsx": () => import("./../../../src/pages/donate-to-ukraine-foundations.tsx" /* webpackChunkName: "component---src-pages-donate-to-ukraine-foundations-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-get-official-information-about-ukraine-tsx": () => import("./../../../src/pages/get-official-information-about-ukraine.tsx" /* webpackChunkName: "component---src-pages-get-official-information-about-ukraine-tsx" */),
  "component---src-pages-how-to-donate-crypto-to-ukraine-tsx": () => import("./../../../src/pages/how-to-donate-crypto-to-ukraine.tsx" /* webpackChunkName: "component---src-pages-how-to-donate-crypto-to-ukraine-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-what-else-can-i-do-tsx": () => import("./../../../src/pages/what-else-can-i-do.tsx" /* webpackChunkName: "component---src-pages-what-else-can-i-do-tsx" */)
}

